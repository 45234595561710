import c1 from "../assets/contacto (1).png"
import c2 from "../assets/contacto (2).png"

const Contacto = () =>{
    return(
        <div>
            <div className="section one" class="mb-20">
                <div class="grid grid-cols-3 gap-2 text-veoblue ">
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center p-9 m-10">
                            <div class=" font-extrabold text-6xl mb-14">QUERÉTARO</div>
                            <div class="font-light text-5xl mb-7">Camino a la Luz #1111 CP 76212 QUERETARO, QRO</div>
                            <div class="font-light text-5xl mb-7">Lunes a Viernes 9:00 a 17:00</div>
                            <div class="font-light text-5xl">Sabados 9:00 a 13:00</div>
                        </div>
                    </div>
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center p-9">
                            <div class="font-extrabold text-6xl mb-14">NUEVO LAREDO, TAMPS</div>
                            <div class="font-light text-5xl mb-7">Carretera UT 9103 Ejido,Francisco Villa, 88284.</div>
                            <div class="font-light text-5xl mb-7">Lunes a Viernes 9:00 a 17:00</div>
                            <div class="font-light text-5xl">Sabados 9:00 a 13:00</div>
                        </div>
                    </div>
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center p-9 m-10">
                            <div class=" font-extrabold text-6xl mb-14">LAREDO, TEXAS</div>
                            <div class="font-light text-5xl mb-7">284 Appaloosa Rd Laredo, TX 78045, Estados Unidos</div>
                            <div class="font-light text-5xl mb-7">Lunes a Viernes 9:00 a 17:00</div>
                            <div class="font-light text-5xl">Sabados 9:00 a 13:00</div>
                        </div>
                    </div>
                    
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center">
                            <iframe class="h-96 " title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3728.161110560268!2d-100.43181278846467!3d20.865555480662938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d4a70653215121%3A0x311094c5c98b230e!2sCorporativo%20Log%C3%ADstico%20VEO!5e0!3m2!1ses-419!2smx!4v1705533071342!5m2!1ses-419!2smx">
                            </iframe>
                        </div>
                    </div>
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center ">
                        <iframe class="h-96" title= "map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47615.7450190319!2d-99.54307057349729!3d27.498465159420142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866118ee8637262d%3A0x7849ca0144fe70c7!2sCorporativo%20Logistico%20VEO!5e0!3m2!1ses-419!2smx!4v1705949038608!5m2!1ses-419!2smx">
                            </iframe>
                        </div>
                    </div>
                    <div class ="flex justify-center">
                        <div class="flex flex-col justify-center items-center ">
                            <iframe class="h-96" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d883.2982900344298!2d-99.59135505965865!3d27.68042423492309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8660e12a13a9f553%3A0xa29e6c2d83a90535!2s289%20Appaloosa%20Rd%2C%20Laredo%2C%20TX%2078045%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1705949869964!5m2!1ses-419!2smx">
                            </iframe>
                            
                        </div>
                    </div>

                    
                </div>
            </div>

            <div className="section two">
                <img src={c1} alt="Contacto" />
            </div>
            

        </div>
    )
}

export default Contacto;