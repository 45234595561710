import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import React, { useEffect } from "react";

export default function Footer (){
    useEffect(() => {
        function redirectToSocialMedia(url) {
            window.location.href = url;
        }

        // Obtén los elementos de los botones después de que el componente esté montado
        var botonLinkedIn = document.getElementById('botonLinkedIn');
        var botonFacebook = document.getElementById('botonFacebook');
        var botonInstagram = document.getElementById('botonInstagram');
        var botonTwitter = document.getElementById('botonTwitter');

        // Agrega eventos de clic a los botones
        botonLinkedIn.addEventListener('click', function() {
            redirectToSocialMedia('https://www.linkedin.com/company/91575009/admin/feed/posts/');
        });

        botonFacebook.addEventListener('click', function() {
            redirectToSocialMedia('https://www.facebook.com/CoporativoLogisticoVEO');
        });

        botonInstagram.addEventListener('click', function() {
            redirectToSocialMedia('https://www.instagram.com/corporativologisticoveo/');
        });

        botonTwitter.addEventListener('click', function() {
            redirectToSocialMedia('https://www.twitter.com');
        });
    }, []);

    return(
        <>
            <footer class="relative bg-veoblue pt-8 pb-6">
                <div class="container mx-auto px-4">
                    <div class="flex flex-wrap text-left lg:text-left">
                    <div class="w-full lg:w-6/12 px-4">
                        <h4 class="text-4xl font-bold text-white">La solucion a tus problemas de logistica</h4>
                        <h5 class="text-xl mt-0 mb-2 text-white font-semibold">
                        Encuentranos en estas Redes Sociales
                        </h5>
                        <div class="mt-6 lg:mb-0 mb-6">
                        <button class="bg-white text-blue-700 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" id="botonLinkedIn" type="button">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </button>
                        <button class="bg-white text-blue-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" id="botonFacebook" type="button">
                            <FontAwesomeIcon icon={faFacebook} />
                        </button>
                        <button class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" id="botonInstagram" type="button">
                            <FontAwesomeIcon icon={faInstagram} />
                        </button>
                        <button class="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" id="botonTwitter" type="button">
                            <FontAwesomeIcon icon={faTwitter} />
                        </button>
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="flex flex-wrap items-top mb-6">
                        <div class="w-full lg:w-4/12 px-4 ml-auto">
                            <span class="block uppercase text-white text-sm font-semibold mb-2">Explorar</span>
                            <ul class="list-unstyled">
                            <li>
                                <a class="text-white hover:text-veored font-semibold block pb-2 text-sm" 
                                href='/nosotros'>Nosotros</a>
                            </li>
                            <li>
                                <a class="text-white hover:text-veored font-semibold block pb-2 text-sm" 
                                href='/nosotros'>Bolsa de Empleo</a>
                            </li>
                            <li>
                                <a class="text-white hover:text-veored font-semibold block pb-2 text-sm" 
                                href='/nosotros'>Blog</a>
                            </li>
                            <li>
                                <a class="text-white hover:text-veored font-semibold block pb-2 text-sm" 
                                href='/nosotros'>Corporativo</a>
                            </li>
                            </ul>
                        </div>
                        
                        </div>
                    </div>
                    </div>
                    <hr class="my-6 border-blueGray-300"/>
                    <div class="flex flex-wrap items-center md:justify-between justify-center">
                    <div class="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div class="text-sm text-white font-semibold py-1">
                        Copyright © 2005
                        <a href="https://www.creative-tim.com/product/notus-js" 
                        class=" text-white hover:text-veored" 
                        > VEO</a>
                        <a 
                        href="https://www.corporativoveo.com.mx" 
                        class="text-white hover:text-blueGray-800"> by Corporativo Logistico VEO
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        </>
    )
}