import { Disclosure } from '@headlessui/react';
import React, { useState, useEffect } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Menu() {
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    // Este efecto se ejecuta cuando cambia la ubicación de la página
    setCurrentLink(window.location.pathname);
  }, []);

  const navigation = [
    { name: 'Inicio', href: '/', current: currentLink === '/' },
    { name: 'Nosotros', href: '/nosotros', current: currentLink === '/nosotros' },
    { name: 'Contacto', href: '/contacto', current: currentLink === '/contacto' },
    { name: 'Empleo', href: '/empleo', current: currentLink === '/empleo' },
    // { name: 'Blog', href: '/blog', current: currentLink === '/blog' },
  ];

  const handleClick = (href) => {
    setCurrentLink(href);
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className=" bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-full px-10 py-3 sm:px-6 lg:px-8 md:w-32 ">
                <div className="flex-row w-full">
                  <div className="flex w-full ">
                    <div className="">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className= {classNames(
                              item.current
                                ? 'bg-blue-600 text-white'
                                : 'text-black hover:bg-blue-900 hover:text-white',
                              'rounded-md px-3 text-xl font-medium py-6'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            onClick={() => handleClick(item.href)}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        </div>
    </>
  )
}