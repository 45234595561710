// import mision from "../assets/fotos (28).jpg";
// import vision from "../assets/fotos (58).jpg";
// import valores from "../assets/fotos (33).jpg";
import p1 from "../assets/nosotros (1).png";
import p2 from "../assets/nosotros (2).png";
import p3 from "../assets/nosotros (3).png";


const Nosotros = () => {
    return (
        <div>
            <div className="Section one">
                <img src={p1} alt="p1" />
            </div>
            <div className="Section two">
                <img src={p2} alt="p2" />
            </div>
            <div className="Section three" class="flex items-center justify-center center">
                <img src={p3} alt="p3" class=" max-h-lvh" />
            </div>

            <div className="Ignore">
             {/* <div>
                    <div class="max-w-full">
                        <div className="mision-visio-valores" class="">
                        
                            <section class="text-blueGray-700 bg-white mt-20">
                                <div class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
                                    <div class="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
                                        <h2 class="mb-8 text-xs font-semibold tracking-widest text-black uppercase title-font"> Servicios Logísticos en Querétaro, México </h2>
                                        <h1 class="mb-8 text-2xl font-black tracking-tighter text-black md:text-5xl title-font"> MISION </h1>
                                        <p class="mb-8 text-4xl leading-relaxed text-left text-blueGray-600 "> 
                                        Corporativo Logístico VEO nos esforzamos por alcanzar objetivos a través de proveer soluciones de logística y la satisfacción total de nuestros clientes. </p>
                                    </div>
                                    <div class="w-full lg:w-1/3 lg:max-w-lg md:w-1/2">
                                        <img class="object-cover w-96 h-96 object-center rounded-2xl " alt="hero" src={mision}/>
                                    </div>
                                </div>
                            </section>
                            <section class="text-blueGray-700 bg-white mt-20">
                                <div class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
                                    <div class="w-full lg:w-1/3 lg:max-w-lg md:w-1/2">
                                        <img class="object-cover w-96 h-96 object-center rounded-2xl " alt="hero" src={vision}/>
                                    </div>
                                    <div class="flex flex-col items-start mb-16 text-right lg:flex-grow md:w-1/2 lg:pr-24 md:pl-16 md:mb-0">
                                        <h1 class="mb-8 text-2xl font-black tracking-tighter text-black md:text-5xl title-font"> VISION </h1>
                                        <p class="mb-8 text-4xl leading-relaxed text-left text-blueGray-600 "> 
                                        Convertirnos en el mejor servicio de logística a nivel nacional que añada valor a la cadena de suministro de la industria mediante la optimización de procesos y la implementación de herramientas de vanguardia que se traduzcan en la reducción de costos para nuestros socios comerciales. </p>
                                    </div>
                                </div>
                            </section>
                            <section class="text-blueGray-700 bg-white mt-20">
                                <div class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28">
                                    <div class="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
                                        <h1 class="mb-8 text-2xl font-black tracking-tighter text-black md:text-5xl title-font"> VALORES </h1>
                                        <p class="mb-8 text-4xl leading-relaxed text-left text-blueGray-600 "> 
                                        &bull; HONESTIDAD <br/>
                                        &bull; SEGURIDAD<br/>
                                        &bull; COMUNICACIÓN<br/>
                                        &bull; SERVICIO<br/>
                                        </p>
                                    </div>
                                    <div class="w-full lg:w-1/3 lg:max-w-lg md:w-1/2">
                                        <img class="object-cover w-96 h-96 object-center rounded-2xl " alt="hero" src={valores}/>
                                    </div>
                                </div>
                            </section>
                            

                        </div>
                        
                    </div>
                </div>
                <div>
                    <div>
                        <section class="text-gray-600 body-font relative">
                            <div class="absolute inset-0 bg-gray-300">
                                <iframe class="" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3728.161110560268!2d-100.43181278846467!3d20.865555480662938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d4a70653215121%3A0x311094c5c98b230e!2sCorporativo%20Log%C3%ADstico%20VEO!5e0!3m2!1ses-419!2smx!4v1705533071342!5m2!1ses-419!2smx">
                                

                                </iframe>
                            </div>
                            <div class="container px-5 py-24 mx-auto flex">
                                <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
                                <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Ponte en Contacto</h2>
                                <p class="leading-relaxed mb-5 text-gray-600">Brindamos servicios de importación y exportación terrestre desde Laredo, TX, y Nvo Laredo, Tamp. Transporte puerta a puerta con unidades propias autorizadas. ¡Optimice su cadena de suministro con nosotros!</p>
                                <div class="relative mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                                </div>
                                <div class="relative mb-4">
                                    <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                                    <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                </div>
                                <button class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Enviar</button>
                                <p class="text-xs text-gray-500 mt-3">Nuestro equipo de ventas se pondra en contacto lo antes posible.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="flex mx-52">
                    <div class="flex flex-col max-w-screen-2xl">
                        <div class= "flex flex-row ">
                            <div class=" my-8 py-4 mx-4 px-8">
                                <div class=" bg-slate-50 shadow rounded-3xl max-w-2xl min-w-xl p-8 pl-8 flex">
                                    <div class="max-w-2xl py-6 ">
                                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Director General</h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">victor.eljure@corporativoveo.com.mx</p>
                                    </div>
                                    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <img class="h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                        <div>
                                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Victor Eljure</h3>
                                            <p class="text-sm font-semibold leading-6 text-indigo-600">Founder / CEO</p>
                                        </div>
                                        </div>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                            <div class=" my-8 py-4 mx-4 px-8">
                                <div class=" bg-slate-50 shadow rounded-3xl max-w-2xl min-w-xl p-8 pl-8 flex">
                                    <div class="max-w-2xl py-6 ">
                                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Asesor Comercial</h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">ventas.queretaro@corporativoveo.com.mx</p>
                                    </div>
                                    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <img class="h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                        <div>
                                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Jonathan Jaramillo</h3>
                                            <p class="text-sm font-semibold leading-6 text-indigo-600">Commercial / Sales Advisor </p>
                                        </div>
                                        </div>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                            <div class=" my-8 py-4 mx-4 px-8">
                                <div class=" bg-slate-50 shadow rounded-3xl max-w-2xl min-w-xl p-8 pl-8 flex">
                                    <div class="max-w-2xl py-6 ">
                                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gerente Nuevo Laredo</h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">gerencia.laredo@corporativoveo.com.mx</p>
                                    </div>
                                    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <img class="h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                        <div>
                                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Jose Luis Sanchez</h3>
                                            <p class="text-sm font-semibold leading-6 text-indigo-600">Administration / Laredo Management</p>
                                        </div>
                                        </div>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class= "flex flex-row">
                            <div class=" my-8 py-4 mx-4 px-8">
                                <div class=" bg-slate-50 shadow rounded-3xl max-w-2xl min-w-xl p-8 pl-8 flex">
                                    <div class="max-w-2xl py-6 ">
                                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gerente Queretaro</h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">gerencia.qro@corporativoveo.com.mx</p>
                                    </div>
                                    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <img class="h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                        <div>
                                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Francisco Bañuelos</h3>
                                            <p class="text-sm font-semibold leading-6 text-indigo-600">Administration / Queretaro Management</p>
                                        </div>
                                        </div>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                            <div class=" my-8 py-4 mx-4 px-8">
                                <div class=" bg-slate-50 shadow rounded-3xl max-w-2xl min-w-xl p-8 pl-8 flex">
                                    <div class="max-w-2xl py-6 ">
                                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gerente Laredo. Tx</h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-600">customer.services@corporativoveo.com.mx</p>
                                    </div>
                                    <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                                    <li>
                                        <div class="flex items-center gap-x-6">
                                        <img class="h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
                                        <div>
                                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Roberto Gonzáles</h3>
                                            <p class="text-sm font-semibold leading-6 text-indigo-600">Administration / Laredo.Tx Management</p>
                                        </div>
                                        </div>
                                    </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            
        </div>
    )

}

export default Nosotros;
