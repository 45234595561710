
//App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Empleo from "./pages/Empleo";
import Nosotros from "./pages/Nosotros";
import Contacto from "./pages/Contacto";
import Blog from "./pages/Blog";
import Menu from "./components/menu";
import Footer from "./components/footer";

const App = () => {

  return (
    <Router>
      <div>
        <Menu />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/empleo" element={<Empleo />} />
          <Route exact path="/nosotros" element={<Nosotros />} />
          <Route exact path="/contacto" element={<Contacto />} />
          <Route exact path="/blog" element={<Blog />} />
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;