import frt1 from "../assets/cards/frt (1).png";
import frt2 from "../assets/cards/frt (2).png";
import frt3 from "../assets/cards/frt (3).png";
import frt4 from "../assets/cards/frt (4).png";
import frt5 from "../assets/cards/frt (5).png";
import frt6 from "../assets/cards/frt (6).png";
import frt7 from "../assets/cards/frt (7).png";
import frt8 from "../assets/cards/frt (8).png";

import bck1 from "../assets/cards/bck (1).png";
import bck2 from "../assets/cards/bck (2).png";
import bck3 from "../assets/cards/bck (3).png";
import bck4 from "../assets/cards/bck (4).png";
import bck5 from "../assets/cards/bck (5).png";
import bck6 from "../assets/cards/bck (6).png";
import bck7 from "../assets/cards/bck (7).png";
import bck8 from "../assets/cards/bck (8).png";

import fotos from "../assets/fotos.png";
import cont from "../assets/contacto.png";


const Empleo = () => {
    return (
        <div class="flex-col">
        <div class="flex row justify-center m-36">

            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt1} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck1} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt2} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck2} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt3} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck3} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt4} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck4} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="flex row justify-center m-36">

            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt5} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck5} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt6} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck6} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt7} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck7} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
            <div class="flex items-center justify-center m-20 ">
                <div class="group h-96 w-80 [perspective:1000px]">
                    <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <div class="absolute inset-0">
                        <img class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40" src={frt8} alt="front" />
                    </div>
                    <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div class="flex min-h-full flex-col items-center justify-center">
                            <div>
                                <img src={bck8} alt="back1" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
            <div>
                <div class="align-middle justify-center flex mb-28">
                    <img src={fotos} alt="fotos" class="flex w-2/4 justify-center " />
                </div>
                <div class="align-middle justify-center flex mb-28">
                    <img src={cont} alt="fotos" class="flex w-2/4 justify-center " />
                </div>
            </div>
        </div>
    )

}

export default Empleo;
