
import Background from "../assets/fotos (26).jpg";
import logo from "../assets/logoVEO_h.png";
import brp from "../assets/clientes/BRP@2x.png";
import cfi from "../assets/clientes/CFI@2x.png";
import mann from "../assets/clientes/MANN@2x.png";
import owens from "../assets/clientes/OWENS@2x.png";
import pcm from "../assets/clientes/PCM@2x.png";
import tecnogolf from "../assets/clientes/TECNOGOLF@2x.png";
import tremec from "../assets/clientes/TREMEC@2x.png";
import mapaveo from "../assets/mapaveo.png";
import servicios from "../assets/servicios.png"

const Home = () => {
  return (
    <div className="">

        <div className="flex flex-col items-center justify-center">
        <div className="relative w-full">
          {/* Imagen de fondo */}
          <img
            className="w-full h-auto object-cover opacity-50 "
            src={Background}
            alt="Corporativo Logistico VEO"
          />

          {/* Contenido superpuesto */}
          <div className="absolute inset-0 flex flex-col justify-center text-center  ">
            <div className=" h-96">
                <img src={logo} alt="Logo" className="mx-auto flex w-72 md:w-max" />
              <h1 className="mt-4 font-bold text-black sm:text-3xl">
                Soluciones de Importación y Exportación en Querétaro, México
              </h1>
              <div className="flex flex-wrap justify-center mt-2 space-x-4 md:mt-8">
                <a
                  href="/contacto"
                  className="px-3 py-1 md:px-6 md:py-3 font-medium text-white bg-veoblue rounded-md hover:bg-white hover:text-black transition duration-300"
                >
                  Contacto
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" bg-white flex items-center justify-center">
            <img src={servicios} alt="Importacion Exportacion DOOR TO DOOR" class=" align-top max-h-lvh " />
        </div>
        <div>
            <div className="veomap">
                <img src={mapaveo} alt="mapa de la ruta veo" class=" max-w-screen"  />

            </div>
        </div>

        <div class="bg-veored sm:py-20">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-white">Exportaciones hechas en los ultimos 5 años</dt>
                <dd class="order-first text-5xl font-bold tracking-tight text-white">+6.2 MIL</dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-white">Importaciones completadas</dt>
                <dd class="order-first text-5xl font-bold tracking-tight text-white">+7.3 MIL</dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-white">Movimientos en USA con B1</dt>
                <dd class="order-first text-5xl font-bold tracking-tight text-white">+1.1 MIL</dd>
            </div>
            </dl>
        </div>
        </div>

        <div className="bg-veoblue sm:py-20">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 className="text-center text-xl font-semibold leading-8 text-white">
¡Descubre una confianza renovada! Únete ahora y sé parte de nuestra comunidad de clientes satisfechos.
                    </h2>
                    <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src={brp}
                        alt="brp"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src={cfi}                  
                        alt="cfi"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain lg:col-span-1"
                        src={mann}             
                        alt="mann"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain sm:col-start-2 lg:col-span-1"
                        src={owens}                     
                        alt="owens"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 col-start-2 max-h-16 w-full object-contain sm:col-start-auto lg:col-span-1"
                        src={pcm}                  
                        alt="pcm"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain sm:col-start-3 lg:col-span-1 lg:col-start-2"
                        src={tecnogolf}                  
                        alt="tecnogolf"
                        width={158}
                        height={48}
                    />
                    <img
                        className="col-span-2 max-h-16 w-full object-contain sm:col-start-5 lg:col-span-1 lg:col-start-4"
                        src={tremec}                  
                        alt="tremec"
                        width={158}
                        height={48}
                    />

                    </div>
                </div>
        </div>

      </div>
  );
};

export default Home;


